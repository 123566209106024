@use 'lib/styles/defs/breakpoints';

.modGoogleMaps {
	width: 100%;
	height: 100%;

	.googleMapsInfoWindowContentWrapper {
		white-space: nowrap;
		overflow: hidden;
		margin-right: 30px;
	}

	&.full-width {
		width: 100vw;
		margin: 0px calc(-50vw - 8px);

		@media (min-width: breakpoints.$breakpoint-sm) {
			width: calc(100vw - 16px);
			margin: 0px calc(-50vw + 8px);
		}

		@media only screen and (min-width: breakpoints.$tabletAndDesktop) {
			width: calc(100vw - 20px);
			margin: 0px -50vw;
		}
	}
}

body.page {
	.google-maps {
		aspect-ratio: 3 / 1;

		& .full-width {
			position: relative;
			width: 100vw;
		}
	}
}
