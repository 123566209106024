@use 'sass:map';
@use '~@swisscom/sdx/stylesheets/sdx/variables/grid';

@mixin background($w: auto, $h: auto, $posX: left, $posY: top, $repeat: no-repeat) {
	background-size: $w $h;
	background-position: $posX $posY;
	background-repeat: $repeat;
}

@mixin spritesheet($w: auto, $h: auto, $sw: auto, $sh: auto, $posX: 0, $posY: 0) {
	@include background($sw, $sh, $posX, $posY);

	width: $w;
	height: $h;

	backface-visibility: hidden;
}

@mixin logo-lifeform-alignments($height, $container-width) {
	$sprite-half-width: 20px;

	&--center {
		left: calc(50% - ($height / 2));
	}

	&--left {
		left: calc(50% - ($container-width / 2) + $sprite-half-width - ($height / 2));
	}

	&--right {
		left: calc(50% + ($container-width / 2) - $sprite-half-width - ($height / 2));
	}
}
@mixin logo-lifeform-large($height) {
	$logo-width: $height;
	$logo-height: $height;
	$steps: 90;

	$logo-sprite-width: $logo-width * $steps;
	$logo-sprite-height: $logo-height;

	pointer-events: none;
	min-width: $logo-width;
	filter: blur(3rem);
	z-index: -1000;
	opacity: 10%;
	aspect-ratio: 1;

	@include spritesheet($logo-width, $logo-height, $logo-sprite-width, $logo-sprite-height);

	// The initial animation of the sprite
	@keyframes initialAnimation-#{$logo-sprite-width} {
		100% {
			background-position: -#{$logo-sprite-width};
		}
	}

	// This second animation simulates a delay of 20s by making the
	// animation duration longer and doing nothing in the beginning.
	@keyframes repeatingAnimation-#{$logo-sprite-width} {
		0% {
			background-position: 0;
		}

		80% {
			background-position: 0;
		}

		100% {
			background-position: -#{$logo-sprite-width};
		}
	}

	animation:
		repeatingAnimation-#{$logo-sprite-width} 30s steps($steps) infinite,
		initialAnimation-#{$logo-sprite-width} 6s steps($steps);
}

.page {
	.modLifeformBgContainer {
		$height: 80vh;

		position: fixed;

		@include logo-lifeform-large($height);

		top: calc(50% - ($height / 2));

		@include logo-lifeform-alignments($height, map.get(grid.$container-widths, ul));

		@media only screen and (max-width: map.get(grid.$grid-breakpoints, xl)) {
			@include logo-lifeform-alignments($height, map.get(grid.$container-widths, xl));
		}

		@media only screen and (max-width: map.get(grid.$grid-breakpoints, lg)) {
			@include logo-lifeform-alignments($height, map.get(grid.$container-widths, lg));
		}

		@media only screen and (max-width: map.get(grid.$grid-breakpoints, md)) {
			@include logo-lifeform-alignments($height, map.get(grid.$container-widths, md));
		}

		@media only screen and (max-width: map.get(grid.$grid-breakpoints, sm)) {
			@include logo-lifeform-alignments($height, map.get(grid.$container-widths, sm));
		}
	}
}

.cmp-container {
	.modLifeformBg {
		container: lifeform / size;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1000;
		pointer-events: none;

		.modLifeformBgContainer {
			$height: 50vh;

			position: absolute;

			@include logo-lifeform-large($height);

			top: calc(50% - ($height / 2));

			&--center {
				left: calc(50% - ($height / 2));
			}

			&--left {
				left: calc(0% - ($height / 2));
			}

			&--right {
				left: calc(100% - ($height / 2));
			}
		}

		@container lifeform (width > 0px) {
			.modLifeformBgContainer {
				$height: 115cqh;

				position: absolute;

				@include logo-lifeform-large($height);

				top: calc(50% - ($height / 2));

				&--center {
					left: calc(50% - ($height / 2));
				}

				&--left {
					left: calc(0% - ($height / 2));
				}

				&--right {
					left: calc(100% - ($height / 2));
				}
			}
		}
	}
}
