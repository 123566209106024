@use 'lib/styles/defs/breakpoints';

.buttongroup {
	.button-group {
		display: block;
		@media (min-width: breakpoints.$breakpoint-xs) {
			display: flex;
		}
		@media (min-width: breakpoints.$breakpoint-sm) {
			margin-top: 8px;
			margin-bottom: 8px;
		}

		> a,
		> div.align-right,
		> div.align-center {
			padding-left: 32px;
			padding-right: 32px;
			margin: 16px;
		}
	}

	&.early_full_width {
		@media screen and (max-width: breakpoints.$breakpoint-max-sm) {
			button,
			sdx-button {
				width: 100%;
				margin-left: 0;
				margin-right: 0;
			}
			.button-group {
				flex-direction: column-reverse;
			}
		}
	}

	&.no_gutter {
		button {
			margin-left: 0;
			margin-right: 0;
		}
	}

	button {
		&.turquoise,
		&.white {
			margin-bottom: 16px;
			margin-top: 16px;
		}
	}
}
