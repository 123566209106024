@use 'sass:color';

@use '~@swisscom/sdx/stylesheets/sdx/variables/colors';

@use 'lib/styles/defs/breakpoints';

.modCountdown.default-view {
	@media screen and (min-width: breakpoints.$breakpoint-md) {
		display: inline-flex;
	}

	.banner {
		z-index: 0;
		display: flex;

		figure {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;
			gap: 6px;
			flex-grow: 1;
			padding: 4px 8px;
			background-color: colors.$color-white;
			color: colors.$color-orchid;
			border-radius: 6px;
			font-size: 20px;
			line-height: 20px;
			font-weight: 520;
			min-width: 0;
			flex-flow: wrap;
			&:not(:last-child) {
				margin-right: 8px;
			}

			@media screen and (min-width: breakpoints.$breakpoint-md) {
				flex-direction: column;
				height: auto;
				gap: 0;
				line-height: 32px;
				font-size: 24px;
			}

			figcaption {
				font-size: 14px;
				line-height: 20px;
				font-weight: normal;
			}
		}
	}

	.theme-orchid-squares & {
		.banner {
			figure {
				background-color: color.change(colors.$color-orchid, $alpha: 0.2);
				color: colors.$color-orchid;
			}
		}
	}
	.theme-hero & {
		display: flex;
		gap: 12px;
		background: white;
		border-radius: 50px;
		border: 1px solid var(--sdx-color-pink);
		padding: 4px 8px;

		@media screen and (min-width: breakpoints.$breakpoint-sm) {
			display: inline-flex;
		}

		@media screen and (min-width: breakpoints.$breakpoint-md) {
			flex-flow: inherit;
			padding: 0 20px;
		}

		.banner {
			display: contents;

			> figure {
				background: transparent;
				color: var(--sdx-color-pink);
				flex-flow: inherit;
				align-items: baseline;
				gap: 8px;
				margin: 0;

				> div {
					font-size: 24px;
					font-weight: 600;
					@media screen and (min-width: breakpoints.$breakpoint-md) {
						font-size: 28px;
					}
				}
			}
		}
	}
}
