@use '~@swisscom/sdx/stylesheets/sdx/variables/colors';
@use 'lib/styles/defs/breakpoints';

$animation-time: 2s;

.modHeroCarousel {
	color: colors.$color-int-blue;

	.carousel__slider {
		display: flex;
	}

	.carousel__pagination {
		width: 100%;
		flex-direction: row;
		justify-content: space-evenly;
		align-content: flex-start;
		align-items: stretch;
		height: 6px;
		pointer-events: none;

		@media screen and (min-width: breakpoints.$breakpoint-md) {
			width: 30%;
		}

		li:first-child {
			border-top-left-radius: 2px;
			border-bottom-left-radius: 2px;
		}
		li:last-child {
			border-top-right-radius: 2px;
			border-bottom-right-radius: 2px;
		}
	}
	.cmp-carousel__indicator {
		opacity: 1;
		height: 4px;
		border-radius: 0;
		margin: 0;
		flex-grow: 1;
		width: unset;
		background-color: colors.$color-gray-tint-8;

		&.cmp-carousel__indicator--active {
			background-color: colors.$color-int-blue;
			opacity: 0.6;
			animation-name: hero-carousel-fade-in-bar;
			animation-duration: $animation-time;
			animation-fill-mode: forwards;
		}
	}
	.cmp-carousel__actions {
		display: flex;
		position: relative;
		left: 0;
		align-items: center;

		@media screen and (min-width: breakpoints.$breakpoint-md) {
			bottom: 50px;
			z-index: 1;
		}
	}

	&.mod {
		.cmp-carousel__item {
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: 100%;
			overflow: hidden;

			.hero-teaser {
				.teaser,
				.image-holder {
					visibility: hidden;
				}

				.image-holder {
					overflow: hidden;
				}

				.teaser {
					min-height: 350px;
					@media screen and (min-width: breakpoints.$breakpoint-md) {
						min-height: 320px;
					}
				}
			}

			&.next {
				.teaser {
					height: 100%;
				}
				.hero-teaser {
					height: 100%;

					.image-holder {
						visibility: visible;

						img {
							position: relative;
							right: calc(-100% - 16px);
							transform: scaleY(0.96);
						}
					}
				}
			}

			&.animate-in {
				.hero-teaser {
					.teaser {
						animation-name: hero-carousel-fade-in;
						animation-duration: $animation-time;
						animation-fill-mode: forwards;
						opacity: 0;
					}
				}
			}
			&.animate-out {
				.hero-teaser {
					.teaser {
						animation-name: hero-carousel-fade-out;
						animation-duration: $animation-time;
						animation-fill-mode: forwards;
						opacity: 1;
						visibility: visible;
					}
				}
			}

			&.animate-in-left {
				.hero-teaser {
					.image-holder {
						img {
							position: relative;
							animation-name: hero-carousel-slide-in-left;
							animation-duration: $animation-time;
							animation-fill-mode: forwards;
							visibility: visible;
							right: -100%;
							margin-left: 16px;
						}
					}
				}
			}
			&.animate-out-left {
				.hero-teaser {
					.image-holder {
						img {
							position: relative;
							visibility: visible;
							left: 0;
							height: 100%;
							animation-name: hero-carousel-slide-out-left;
							animation-duration: $animation-time;
							animation-fill-mode: forwards;
						}
					}
				}
			}
			&.next.animate-preview-in {
				.hero-teaser {
					.image-holder {
						img {
							position: relative;
							right: -200%;
							animation-name: hero-carousel-preview-in;
							animation-duration: $animation-time;
							animation-fill-mode: forwards;
						}
					}
				}
			}
			&.animate-in-right {
				.hero-teaser {
					.image-holder {
						img {
							position: relative;
							animation-name: hero-carousel-slide-in-right;
							animation-duration: $animation-time;
							animation-fill-mode: forwards;
							visibility: visible;
							left: -100%;
						}
					}
				}
			}
			&.next.animate-out-right {
				.hero-teaser {
					.image-holder {
						img {
							position: relative;
							visibility: visible;
							right: 0;
							height: 100%;
							animation-name: hero-carousel-slide-out-right;
							animation-duration: $animation-time;
							animation-fill-mode: forwards;
							margin-left: 16px;
						}
					}
				}
			}
			&.animate-preview-out {
				.hero-teaser {
					.image-holder {
						img {
							position: relative;
							right: calc(-100% - 16px);
							animation-name: hero-carousel-preview-out;
							animation-duration: $animation-time;
							animation-fill-mode: forwards;
							visibility: visible;
							margin-left: 16px;
							transform: scaleY(0.96);
						}
					}
				}
			}

			&.cmp-carousel__item--active {
				position: relative;
				z-index: 1;

				.hero-teaser {
					.teaser,
					.image-holder {
						visibility: visible;
					}
				}
			}
		}
	}
}

// Hero carousel animations
@keyframes hero-carousel-fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes hero-carousel-fade-out {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		visibility: hidden;
	}
}

@keyframes hero-carousel-fade-in-bar {
	100% {
		opacity: 1;
	}
}

@keyframes hero-carousel-slide-out-left {
	0% {
		transform: scaleY(1);
	}
	100% {
		left: calc(-100% - 16px);
		transform: scaleY(0.96);
		visibility: hidden;
	}
}

@keyframes hero-carousel-slide-in-left {
	0% {
		transform: scaleY(0.96);
	}
	100% {
		right: 0;
		height: 100%;
		margin-left: 0;
		transform: scaleY(1);
	}
}

@keyframes hero-carousel-preview-in {
	100% {
		right: calc(-100% - 16px);
	}
}

@keyframes hero-carousel-preview-out {
	100% {
		right: -200%;
		visibility: hidden;
	}
}

@keyframes hero-carousel-slide-out-right {
	0% {
		transform: scaleY(1);
	}
	100% {
		right: -100%;
		transform: scaleY(0.96);
	}
}

@keyframes hero-carousel-slide-in-right {
	0% {
		transform: scaleY(0.96);
	}
	100% {
		left: 0;
		height: 100%;
		transform: scaleY(1);
	}
}
