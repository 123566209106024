@use 'lib/styles/defs/breakpoints';
@mixin loader {
	.loader-holder {
		display: flex;
		justify-content: center;
	}
}
.topic-selector-dialog-content {
	@media only screen and (min-width: breakpoints.$breakpoint-md) {
		width: 680px;
	}
	.call-from-net-multiple-option-dialog {
		@include loader;
		.selector-holder {
			.row {
				.topic-selector-dialog-input-item {
					padding-bottom: 16px;
					@media only screen and (min-width: breakpoints.$breakpoint-md) {
						padding-bottom: 0;
					}
				}
				@media only screen and (min-width: breakpoints.$breakpoint-md) {
					width: 100%;
				}
			}
		}
	}
}

.modCallFromNet {
	@include loader;

	&.center {
		.cfn-open,
		.hotline,
		.closed {
			text-align: center;

			.hotline-info {
				display: flex;
				justify-content: center;
				.text-holder {
					text-align: left;
					display: block;
				}
			}

			h4,
			p {
				text-align: center;
			}
		}
	}
	&.left {
		.cfn-open,
		.hotline,
		.closed {
			text-align: left;

			h4,
			p {
				text-align: left;
			}
		}
	}
	&.right {
		.cfn-open,
		.hotline,
		.closed {
			text-align: right;

			.hotline-info {
				display: flex;
				justify-content: flex-end;
				.text-holder {
					text-align: left;
					display: block;
				}
			}

			h4,
			p {
				text-align: right;
			}
		}
	}
}
