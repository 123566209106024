$arrow-size: 40px;
$arrow-offset: 20px;

$animation: cubic-bezier(0.4, 0, 0.2, 1);

.arrow {
	position: absolute;
	top: calc(0.5 * (100% - $arrow-size - var(--sdx-baseline-3)));
	z-index: 5;
	padding: 10px; // Safe zone

	&.next {
		right: -$arrow-offset;
		padding-right: 0;
	}

	&.prev {
		left: -$arrow-offset;
		padding-left: 0;
	}

	.arrow-icon {
		cursor: pointer;
		border: 1px solid var(--sdx-color-gray-tint-7);
		border-radius: 50%;
		background-color: var(--sdx-color-sc-white);
		width: $arrow-size;
		height: $arrow-size;
		display: flex;
		justify-content: center;
		opacity: 1;
		transform: scale(1);
		transition: all 200ms $animation;
		outline: none;

		sdx-icon {
			position: absolute;
			top: 7px; // Needed for vertical symmetry within the circular buttons
			align-items: center;

			transition: color 200ms $animation;
			color: var(--sdx-color-int-blue);
		}

		&:hover {
			border-color: var(--sdx-color-gray-tint-4);

			sdx-icon {
				color: var(--sdx-color-int-blue--active);
			}
		}
	}
}
