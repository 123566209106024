@use '~@swisscom/sdx/stylesheets/sdx/variables/colors';
@use 'lib/styles/defs/breakpoints';

.modSocialTools {
	min-width: 250px;
	ul {
		margin: -24px;
		li {
			line-height: 24px;
			text-align: center;
			&:not(:last-of-type) {
				border-bottom: 1px solid colors.$color-aluminum-tint-2;
			}

			a {
				padding: 12px 24px;
				border-bottom: none;
				text-decoration: none;
				display: block;
				&:hover {
					background-color: colors.$color-int-blue;
					color: colors.$color-sc-white;
					text-decoration: none;
				}
			}
		}
	}
}

.tools-label {
	color: colors.$color-gray;
	font-size: 14px;
	&:hover {
		color: colors.$color-int-blue;
	}
}

.st-column {
	display: flex;
	.tools-flex {
		display: flex;
		flex-direction: column;
		align-items: center;
		.tools-label {
			margin-top: 2px;
		}
	}
}

.st-column-reverse {
	display: flex;
	.tools-flex {
		display: flex;
		flex-direction: column-reverse;
		align-items: center;
		.tools-label {
			margin-bottom: 2px;
		}
	}
}
.st-row {
	display: flex;
	.tools-flex {
		display: flex;
		flex-direction: row;
		align-items: center;
		.tools-label {
			margin-left: 2px;
		}
	}
}
.st-row-reverse {
	display: flex;
	.tools-flex {
		display: flex;
		flex-direction: row-reverse;
		align-items: center;
		.tools-label {
			margin-right: 2px;
		}
	}
}

.st-align-left:not(.st-column, .st-column-reverse, .st-row, .st-row-reverse):has(.tools-label),
.socialtools:not(.st-column, .st-column-reverse, .st-row, .st-row-reverse):has(.tools-label) {
	display: flex;
	.tools-flex {
		display: flex;
		flex-direction: column;
		align-items: center;
		.tools-label {
			margin-top: 2px;
		}
	}
}

@media only screen and (min-width: breakpoints.$breakpoint-md) {
	.st-align-left.st-column,
	.st-align-left.st-column-reverse,
	.st-align-left.st-row,
	.st-align-left.st-row-reverse {
		flex-direction: row;
	}

	.st-align-right.st-column,
	.st-align-right.st-column-reverse,
	.st-align-right.st-row,
	.st-align-right.st-row-reverse {
		flex-direction: row-reverse;
	}

	.st-align-center.st-column,
	.st-align-center.st-column-reverse,
	.st-align-center.st-row,
	.st-align-center.st-row-reverse {
		flex-direction: column;
		align-items: center;
	}

	.st-align-left:not(.st-column, .st-column-reverse, .st-row, .st-row-reverse) {
		text-align: left;
	}

	.st-align-right:not(.st-column, .st-column-reverse, .st-row, .st-row-reverse) {
		text-align: right;
	}

	.st-align-center:not(.st-column, .st-column-reverse, .st-row, .st-row-reverse) {
		text-align: center;
	}

	.st-align-center:not(.st-column, .st-column-reverse, .st-row, .st-row-reverse):has(.tools-label) {
		display: flex;
		flex-direction: column;
		.tools-flex {
			display: flex;
			flex-direction: column;
			align-items: center;
			.tools-label {
				margin-top: 2px;
			}
		}
	}

	.st-align-right:not(.st-column, .st-column-reverse, .st-row, .st-row-reverse):has(.tools-label) {
		display: flex;
		flex-direction: row-reverse;
		.tools-flex {
			display: flex;
			flex-direction: column;
			align-items: center;
			.tools-label {
				margin-top: 2px;
			}
		}
	}
}
