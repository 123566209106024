@use '~@swisscom/sdx/stylesheets/sdx/variables/colors';
@use '~@swisscom/sdx/stylesheets/sdx/variables/sizes';

ol.list.circled-number-list {
	> li {
		position: relative;
		display: list-item;
		padding-bottom: sizes.$baseline;
		padding-left: sizes.$baseline-4;

		&::before {
			background-color: colors.$color-int-blue;
			position: absolute;
			top: calc(50% - 8px);
			transform: translateY(-50%);
			left: 0;
			width: 24px;
			height: 24px;
			border-radius: 50%;
			content: counter(mycounter);
			color: colors.$color-white;
			font-size: 14px;
			line-height: 16px;
			display: flex;
			justify-content: center;
			align-items: center;
			padding-right: 0;
		}
	}

	&.circled-number-list-top {
		> li {
			&::before {
				position: absolute;
				top: 0px;
				transform: none;
			}
		}
	}
}

span.circled--icon {
	.icon:before {
		background-color: colors.$color-orchid;
		color: colors.$color-white;
		border-radius: 50%;
		padding: 5px;
	}
}

.cmp-text {
	ol.list:not(.circled-number-list),
	ul.list {
		margin-top: 0;
		margin-bottom: 0;

		.grey-check-list & {
			> li {
				&::before {
					color: colors.$color-gray;
				}
			}
		}
	}
}

.font--bold {
	font-weight: 700;
}

.turquoise-check-list {
	ul.list.single-line {
		.icon-check-mark-2-circle {
			display: block;
			align-items: flex-start;
			margin-left: 32px;

			&::before {
				color: colors.$color-turquoise;
				margin-left: -32px;
				font-size: 24px;
				padding-right: 6px;
				top: 0;
			}
		}
	}
}

.line-height-28 {
	line-height: 28px;
}

.table-text-wrap {
	.table td,
	.table th {
		white-space: normal;
	}
}

.text {
	sdx-tag {
		display: inline-block;
	}
}
