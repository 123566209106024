@use '~@swisscom/sdx/stylesheets/sdx/variables/colors';

.modHoverContainer {
	position: relative;
	> a {
		display: block;
		color: inherit;
		transition: none;
		border: 0;
		height: 100%;

		&:hover,
		&:focus {
			transition: none;
			color: inherit;
		}
	}
	&.initializing {
		> a,
		.normalDisplay,
		.hoverDisplay {
			height: auto;
		}
	}

	.normalDisplay,
	.hoverDisplay {
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;

		.component-container {
			height: 100%;
		}
	}

	.normalDisplay {
		opacity: 1;
		position: relative;
	}
	.hoverDisplay {
		opacity: 0;
		position: absolute;
	}

	.containerBorder {
		border: 1px solid colors.$color-gray-tint-8;
	}

	&:hover,
	&:focus {
		.normalDisplay {
			opacity: 0;
			position: 'absolute';
		}
		.hoverDisplay {
			opacity: 1;
			position: 'relative';
		}
	}

	&.fadein,
	&.fadein:hover {
		.normalDisplay,
		.hoverDisplay {
			transition: opacity 0.4s ease-in-out;
		}
	}

	@media (pointer: coarse) {
		&.normal {
			.normalDisplay {
				opacity: 1;
				position: relative;
			}
			.hoverDisplay {
				opacity: 0;
				position: absolute;
			}
		}
		&.hover {
			.normalDisplay {
				opacity: 0;
				position: absolute;
			}
			.hoverDisplay {
				opacity: 1;
				position: relative;
			}
		}
		&.touch {
			.show {
				opacity: 1;
				position: relative;
			}
			.dont-show {
				opacity: 0;
				position: absolute;
			}
		}
	}
	.teaser {
		margin-top: 0;
	}

	.remove-underlined-links & {
		a {
			text-decoration: none;
		}
	}
}

.container-as-sales-link {
	padding: 0;
	.modHoverContainer {
		cursor: pointer;
		outline: none;
		background-color: rgba(255, 255, 255, 0.85);
		color: colors.$color-navy;
		position: relative;

		.normalDisplay,
		.hoverDisplay {
			display: flex;
			padding-left: 36px;
			justify-content: center;
			flex-direction: column;
			align-items: flex-start;
			.component-container {
				height: auto;
				.cmp-container {
					display: flex;
					align-items: center;
					h4 {
						margin-bottom: 0;
					}
				}
			}
		}

		@media screen and (max-width: 1024px) {
			min-height: 70px !important;
			max-height: 160px;
			overflow: hidden;
			.normalDisplay {
				display: none;
			}
			.hoverDisplay {
				opacity: 1;
				position: relative;
				.animatedsvg {
					margin-right: 20px;
				}
			}
		}

		@media screen and (min-width: 1025px) {
			z-index: 3;
			align-items: center;
			height: 100%;
			width: 170px;
			position: absolute;
			top: 0;
			right: 0;
			.normalDisplay,
			.hoverDisplay {
				align-items: center;
				padding: 0 2px;
				.component-button {
					align-self: flex-start;
					a:link,
					a:visited {
						color: colors.$color-navy;
					}
					a:hover,
					a:focus,
					a:active,
					a:visited:hover {
						color: colors.$color-blue;
					}
				}
				.component-container {
					align-self: center;
					.cmp-container {
						flex-direction: column;
					}
				}
			}
		}

		@media screen and (min-width: 1340px) {
			width: 200px;
		}
		@media screen and (min-width: 1400px) {
			width: 230px;
		}
		@media screen and (min-width: 1440px) {
			width: 244px;
		}
		@media screen and (min-width: 1600px) {
			width: 315px;
		}
	}
}
