@use '~@swisscom/sdx/stylesheets/sdx/variables/colors';
@use 'lib/styles/defs/breakpoints';

.mod-teaser {
	white-space: normal;
	position: relative;
	color: inherit;
	height: 100%;

	&__wrapper {
		border-top: none;
		height: 100%;

		&:before {
			content: '';
			width: 1px;
			margin-left: -1px;
			float: left;
			height: 0;
			padding-top: 110%;
		}

		&:after {
			content: '';
			display: table;
			clear: both;
		}
	}

	&__content-wrapper {
		display: flex;
		flex-flow: column nowrap;
		position: relative;
		height: 100%;
		border: 1px solid #d6d8db;

		&:before {
			content: '';
			border-top: 4px solid colors.$color-int-blue--active;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			opacity: 0;
			transition: opacity 100ms ease-out;
		}
	}

	&__featured-image {
		background-size: cover;
		background-position: top center;
		padding-top: 87%;

		@media screen and (min-width: breakpoints.$breakpoint-sm) {
			padding-top: 47%;
		}

		@media screen and (min-width: breakpoints.$breakpoint-md) {
			padding-top: 63%;
		}

		@media screen and (min-width: breakpoints.$breakpoint-lg) {
			padding-top: 75%;
		}

		@media screen and (min-width: breakpoints.$breakpoint-ul) {
			padding-top: 70%;
		}
	}

	&__header {
		flex: 0 0 17%;

		& .header__title {
			font-weight: bold;
		}

		& > .header__title:first-child {
			margin-top: 1em;
		}
	}

	&__body {
		flex: 1 1 auto;
	}

	&__footer {
		flex: 0 1 20%;
		margin-top: auto;

		display: flex;
		align-items: flex-end;
	}

	&__more-link {
		margin-left: 1rem;
		position: relative;

		&:before {
			content: '>';
			position: absolute;
			left: -1rem;
		}
	}

	&:hover,
	&:active,
	&:focus,
	&:focus-within {
		border-bottom: none;
		color: inherit;

		.mod-teaser__wrapper {
			cursor: pointer;
		}

		.mod-teaser__content-wrapper:before {
			opacity: 1;
		}
	}
}

.mod-teaser--enhanced {
	height: auto;

	& .mod-teaser {
		&__wrapper {
			background-position: center center;
			background-size: cover;
			background-repeat: no-repeat;
			overflow: hidden;
			display: flex;
			flex-flow: row nowrap;

			&:before {
				padding-top: 30%;
			}
		}

		&__content-wrapper {
			width: 50%;
			border: none;
			height: initial;

			&:before {
				content: '';
				border-top: none;
				transition: none;
			}
		}

		&__more-link {
			margin-left: 0;

			&:before {
				content: '';
			}
		}

		&:hover,
		&:active,
		&:focus,
		&:focus-within {
			border-bottom: none;
			color: inherit;

			.mod-teaser__content-wrapper:before {
				opacity: 1;
			}
		}
	}
}

[class^='col-'][class$='-6'],
[class^='col-'][class$='-5'],
[class^='col-'][class$='-4'],
[class^='col-'][class$='-3'],
[class^='col-'][class$='-2'],
[class^='col-'][class$='-1'] {
	& > .mod-teaser {
		&.mod-teaser--enhanced {
			.mod-teaser__wrapper:before {
				padding-top: 64%;
			}
			.mod-teaser__content-wrapper {
				width: 100% !important;
				max-width: 100% !important;
				flex: 0 0 100% !important;
			}
		}
	}
}

@media (max-width: 768px) {
	.mod-teaser--no-image {
		.mod-teaser__wrapper {
			&:before {
				padding-top: 80%;
			}
		}
	}
}

@media (max-width: 480px) {
	.mod-teaser--no-image {
		.mod-teaser__wrapper {
			&:before {
				padding-top: 60%;
			}
		}
	}
}

.mod-teaser--no-interaction {
	&:hover,
	&:active,
	&:focus,
	&:focus-within {
		.mod-teaser__wrapper {
			cursor: initial;
		}
		.mod-teaser__content-wrapper:before {
			opacity: 0 !important;
		}
	}
}
