@import './animations_xs.scss';

@keyframes md-first-to-previous {
	from {
		left: 0;
		width: 88%;
	}
	to {
		width: 30%;
		left: calc(-24% - 20px);
	}
}

@keyframes md-next-large-to-current {
	from {
		left: calc(88% + 20px);
		width: 30%;
	}
	to {
		left: 6%;
		width: 88%;
	}
}

@keyframes md-to-small-left-preview {
	from {
		left: calc(112% + 20px);
	}
	to {
		left: calc(94% + 10px);
	}
}

@keyframes md-last-to-current {
	from {
		left: calc(94% + 10px);
		width: 30%;
	}
	to {
		width: 88%;
		left: calc(12% - 10px);
	}
}

@keyframes md-current-large-previous {
	from {
		left: 6%;
		width: 88%;
	}
	to {
		width: 30%;
		left: calc(-18% - 30px);
	}
}

@keyframes md-previous-out {
	from {
		display: inline-block;
		left: calc(-24% - 20px);
	}
	to {
		left: calc(-54% - 30px);
		display: inline-block;
	}
}

@keyframes md-next-to-current {
	from {
		left: calc(94% + 10px);
		width: 30%;
	}
	to {
		left: 6%;
		width: 88%;
	}
}

@keyframes md-next-in {
	from {
		left: calc(126% + 10px);
	}
	to {
		left: calc(94% + 10px);
	}
}

@keyframes md-current-to-previous {
	from {
		left: 6%;
		width: 88%;
	}
	to {
		left: calc(-24% - 20px);
		width: 30%;
	}
}

@keyframes md-previous-to-first {
	from {
		left: calc(-24% - 20px);
		width: 30%;
	}
	to {
		left: 0;
		width: 88%;
	}
}

@keyframes md-current-to-next-large {
	from {
		left: 6%;
		width: 88%;
	}
	to {
		left: calc(88% + 20px);
		width: 30%;
	}
}

@keyframes md-next-out {
	from {
		left: calc(94% + 10px);
		display: inline-block;
	}
	to {
		left: calc(124% + 10px);
		display: inline-block;
	}
}

@keyframes md-previous-large-to-current {
	from {
		left: calc(-18% - 30px);
		width: 30%;
	}
	to {
		left: 6%;
		width: 88%;
	}
}

@keyframes md-last-to-next {
	from {
		left: calc(12% - 10px);
		width: 88%;
	}
	to {
		left: calc(94% + 10px);
		width: 30%;
	}
}

@keyframes md-previous-in {
	from {
		left: calc(-54% - 10px);
	}
	to {
		left: calc(-24% - 20px);
	}
}

@keyframes md-previous-to-current {
	from {
		left: calc(-24% - 20px);
		width: 30%;
	}
	to {
		left: 6%;
		width: 88%;
	}
}

@keyframes md-current-to-next {
	from {
		left: 6%;
		width: 88%;
	}
	to {
		left: calc(94% + 10px);
		width: 30%;
	}
}

@keyframes lg-first-to-previous {
	from {
		left: 0;
		width: 60%;
	}
	to {
		left: calc(-20% - 40px);
		width: 30%;
	}
}

@keyframes lg-first-right-to-full {
	from {
		left: calc(60% + 20px);
		width: 30%;
	}
	to {
		left: calc(10% - 20px);
		width: 60%;
	}
}

@keyframes lg-first-right-preview-to-full-preview {
	from {
		left: calc(90% + 40px);
	}
	to {
		left: 70%;
	}
}

@keyframes lg-last-to-full {
	from {
		width: 30%;
		left: 70%;
	}
	to {
		left: 40%;
		width: 60%;
	}
}

@keyframes lg-full-to-large-previous {
	from {
		width: 60%;
	}
	to {
		width: 30%;
	}
}

@keyframes lg-preview-right-to-full {
	from {
		width: 30%;
		left: 70%;
	}
	to {
		left: calc(10% - 20px);
		width: 60%;
	}
}

@keyframes lg-next-in {
	from {
		left: calc(100% + 20px);
	}
	to {
		left: 70%;
	}
}

@keyframes lg-full-to-previous {
	from {
		left: calc(10% - 20px);
		width: 60%;
	}
	to {
		left: calc(-20% - 40px);
		width: 30%;
	}
}

@keyframes lg-previous-out {
	from {
		left: calc(-20% - 40px);
	}
	to {
		left: calc(-50% - 40px);
	}
}

@keyframes lg-previous-to-first {
	from {
		left: calc(-20% - 40px);
		width: 30%;
	}
	to {
		left: 0;
		width: 60%;
	}
}

@keyframes lg-full-to-first-next {
	from {
		left: calc(10% - 20px);
		width: 60%;
	}
	to {
		left: calc(60% + 20px);
		width: 30%;
	}
}

@keyframes lg-full-preview-to-preview-next {
	from {
		left: 70%;
	}
	to {
		left: calc(90% + 40px);
	}
}

@keyframes lg-left-full-to-full {
	from {
		width: 30%;
	}
	to {
		width: 60%;
	}
}

@keyframes lg-last-to-preview-right {
	from {
		width: 60%;
		left: 40%;
	}
	to {
		left: 70%;
		width: 30%;
	}
}

@keyframes lg-previous-to-full {
	from {
		width: 30%;
		left: calc(-20% - 40px);
	}
	to {
		left: calc(10% - 20px);
		width: 60%;
	}
}

@keyframes lg-previous-in {
	from {
		left: calc(-50% - 40px);
	}
	to {
		left: calc(-20% - 40px);
	}
}

@keyframes lg-full-to-right-preview {
	from {
		left: calc(10% - 20px);
		width: 60%;
	}
	to {
		left: 70%;
		width: 30%;
	}
}

@keyframes lg-right-preview-out {
	from {
		left: 70%;
	}
	to {
		left: calc(100% + 20px);
	}
}
