.text-hero,
.text-d1,
.text-d2,
.text-d3,
.text-h1,
.text-h2,
.text-h3,
.text-h4,
.text-h5,
.text-h6 {
	.cmp-title__text {
		line-height: inherit;
		letter-spacing: inherit;
		font-size: inherit;
		font-weight: inherit;
	}
}

.cmp-title__text {
	.title-align-left & {
		text-align: left;
	}
	.title-align-center & {
		text-align: center;
	}
	.title-align-right & {
		text-align: right;
	}
}
