@use 'lib/styles/defs/breakpoints';

.hero-teaser {
	display: flex;
	flex-direction: column-reverse;

	@media screen and (min-width: breakpoints.$breakpoint-md) {
		flex-direction: row;
	}

	.teaser {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: baseline;
		width: 100%;

		@media screen and (min-width: breakpoints.$breakpoint-md) {
			width: 45%;
		}

		.badge {
			border-radius: 12px;
		}
	}

	.image {
		width: 100%;
		@media screen and (min-width: breakpoints.$breakpoint-sm) {
			width: 80%;
		}
		img {
			border-radius: 12px;
		}
	}

	.image-holder {
		width: 100%;
		align-items: center;
		display: flex;
		flex-direction: row;

		@media screen and (min-width: breakpoints.$breakpoint-md) {
			width: 55%;
		}
	}
}
