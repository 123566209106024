@use '~@swisscom/sdx/stylesheets/sdx/variables/colors';

sdx-ribbon {
	.ribbon-parsys {
		min-height: 25px;
		.aem-Grid {
			height: 100%;
		}
	}

	&[theme='sales'] {
		.ribbon-border {
			border: 1px solid colors.$color-orchid;
			border-radius: 2px;
		}
	}

	&[theme='hint'] {
		.ribbon-border {
			border: 1px solid colors.$color-turquoise;
			border-radius: 2px;
		}
	}

	&[theme='topic-dark'],
	&[theme='topic-light'] {
		.ribbon-border {
			border: 1px solid colors.$color-navy;
			border-radius: 2px;
		}
	}
}

.modSdxTag {
	sdx-tag {
		justify-content: center;
		transform: translateY(50%);
	}

	.ribbon-tag-container.ribbon-border {
		&[theme='standard'] {
			border: 1px solid colors.$color-navy;
			border-radius: 2px;
		}

		&[theme='sales'] {
			border: 1px solid colors.$color-orchid;
			border-radius: 2px;
		}

		&[theme='new'] {
			border: 1px solid colors.$color-navy;
			border-radius: 2px;
		}
	}
}
