@mixin image-positions {
	.sc-teaser-img-right &,
	.sc-teaser-img-left & {
		display: flex;
		justify-content: space-between;
		.centralized_over {
			width: auto;
			overflow: visible;
			.sticker_holder {
				align-self: center;
				width: 100%;
			}
		}
		.teaser-standard-container {
			width: 100%;
		}
	}
	.sc-teaser-img-right & {
		flex-direction: row-reverse;
		.centralized_over[class*='padding-'] {
			padding-left: 0 !important;
		}
	}
	.sc-teaser-img-left & {
		flex-direction: row;
		.centralized_over[class*='padding-'] {
			padding-right: 0 !important;
		}
	}

	.sc-teaser-img-bottom & {
		display: flex;
		flex-direction: column-reverse;
		.centralized_over {
			width: auto;
			overflow: visible;
			&[class*='padding-'] {
				padding-top: 0 !important;
			}
			.sticker_holder {
				align-self: center;
				width: 100%;
			}
		}
	}
}
