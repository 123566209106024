@use 'lib/styles/defs/breakpoints';
@use 'lib/styles/defs/mixins';

.hero-banner {
	border-radius: 12px;
	overflow: hidden;
	position: relative;
	height: auto;

	.background-desktop,
	.background-mobile {
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: -1;
		height: inherit;
	}
	h4,
	h2,
	.description {
		color: var(--headline-color);
	}
	.hero-banner-content {
		display: flex;
		position: relative;
		flex-direction: column;
		height: 560px;
		padding: 24px 16px;
		gap: 24px;
		flex: 1 1 0%;
		text-decoration: none;
		z-index: 1;

		@media screen and (min-width: breakpoints.$breakpoint-sm) {
			padding: 32px 32px;
		}
		@media screen and (min-width: breakpoints.$breakpoint-md) {
			flex-direction: row;
			justify-content: space-between;
			height: 608px;
		}

		> .banner {
			display: flex;
			flex-direction: column;
			justify-content: center;
			flex-basis: 50%;
			align-items: normal;

			@media screen and (min-width: breakpoints.$breakpoint-md) {
				.title {
					font-size: 54px;
					line-height: 64px;
				}
			}
			.button-wrapper {
				display: flex;
				flex-direction: column;
				row-gap: 16px;
				@media screen and (min-width: breakpoints.$breakpoint-sm) {
					flex-direction: row;
					column-gap: 16px;
				}
			}
			.hero-countdown-container {
				.countdown {
					margin-bottom: 32px;
					@media screen and (min-width: breakpoints.$breakpoint-md) {
						margin-bottom: 50px;
					}
				}
			}
		}

		.image-wrapper {
			display: flex;
			align-items: center;
			flex-basis: 50%;
			position: relative;

			img {
				display: block;
				aspect-ratio: 4/3;
				height: 100%;
				object-fit: scale-down;
				transition: transform 600ms cubic-bezier(0.4, 0, 0.2, 1) 0s;

				&:hover {
					transform: scale(1.05);
				}
			}
		}
		.sdx-stickers {
			animation: flipBubble 6s linear infinite forwards running;
			position: absolute;
			right: -10%;
			bottom: -15%;
			backface-visibility: hidden;
			scale: 0.6;

			@media (min-width: breakpoints.$breakpoint-md) {
				scale: 0.7;
				right: -10%;
				bottom: 10%;
			}
			@media (min-width: breakpoints.$breakpoint-lg) {
				scale: 1;
				right: 9%;
				bottom: -2%;
			}
			display: grid;

			> sdx-sticker-circle {
				grid-column: 1;
				grid-row: 1;
			}
			.back-face {
				z-index: -1;
				transform: rotateY(180deg);
			}

			&.no-flipping {
				animation: none;
			}
		}
	}

	video,
	.gradient-dark & .gradient-dark-overlay {
		display: block;
		object-fit: cover;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
	}

	.gradient-dark-overlay {
		display: none;
		background-image: linear-gradient(90deg, #1d1d1d 0%, rgba(29, 29, 29, 0) 100%);
		@media (min-width: breakpoints.$breakpoint-sm) {
			background-image: linear-gradient(90deg, #1d1d1d 0%, rgba(29, 29, 29, 0) 55%);
		}
	}

	&.animated-bg-gradient-red {
		@extend %twinkleAnimation;
	}
	&.bg-gradient-pink {
		@include gradientPink;
	}
}
