.modScrollyTelling {
	--scrolly-top-offset: calc(var(--sdx-header-height-sticky) + 20px);
	--slide-aspect-ratio: 4 / 3;
	--slide-bottom-margin: 30%;
	--fixed-slide-offset: 40%;

	> .slide,
	> .spacer {
		aspect-ratio: var(--slide-aspect-ratio);
		position: sticky;
		top: var(--scrolly-top-offset);
		overflow: hidden;
		opacity: 0;
		transition: opacity 1.5s ease-in-out;
		&:first-child {
			opacity: 1;
		}
		&.fixed {
			opacity: 0;
			position: static;
			translate: 0 var(--fixed-slide-offset);
		}
		&.current {
			opacity: 1;
		}
	}
	> .slide {
		margin-bottom: var(--slide-bottom-margin);
	}

	&.editMode > * {
		--slide-aspect-ratio: auto;
		opacity: 1;
	}
}
