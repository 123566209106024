@use 'lib/styles/defs/breakpoints';
@use '~@swisscom/sdx/stylesheets/sdx/variables/colors';

.table.modTable {
	thead,
	tbody {
		font-size: 16px;
		@media screen and (min-width: breakpoints.$breakpoint-sm) {
			font-size: 18px;
		}
	}

	.mobile-friendly {
		th {
			width: 40%;
		}

		td {
			white-space: break-spaces;
		}
	}

	.table--disabled-border table {
		border: none;
	}

	.table--disabled-th-border thead tr {
		border: none;
	}

	.table--disabled-tb-border tbody tr {
		border: none;
	}

	.table-column-highlight & {
		table tbody tr:hover {
			background-color: colors.$color-horizon;
		}
	}
}
