@use 'lib/styles/defs/breakpoints';

.modMessagingChannels {
	.show-grid {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;

		.contact-channel {
			display: flex;
		}

		@media screen and (min-width: breakpoints.$breakpoint-md) {
			flex-direction: row;
			.contact-channel {
				min-width: 200px;
			}
		}
	}
}
