@use 'lib/styles/defs/breakpoints';
.article {
	.author-template {
		margin-top: -16px;
		.author-top {
			position: relative;
			height: 100%;
			margin-bottom: 74px;

			.author-background {
				overflow: hidden;
				left: 50%;
				margin-left: -50vw;
				margin-right: -50vw;
				max-width: 100vw;
				position: absolute;
				right: 50%;
				width: 100vw;
				z-index: -1;
				height: inherit;
			}

			.author-head {
				padding: 50px 0;
				@media only screen and (min-width: breakpoints.$breakpoint-sm) {
					padding: 96px 0 102px;
				}

				.author-image {
					width: 204px;
					height: 204px;
				}
			}
		}
	}

	.news-template {
		.fullbleed {
			position: relative;
			aspect-ratio: 16 / 9;
			left: 50%;
			width: 100vw;
			background-size: cover;
			background-position: center;
			margin-left: -50vw;
			max-width: 1920px;

			&.min-height {
				min-height: 432px;
			}
			&.overlay {
				margin-top: -16px;
			}

			@media only screen and (min-width: 1920px) {
				margin-left: -960px;
			}
		}

		.stage-overlay {
			position: absolute;
			bottom: 16px;
			color: white;

			@media only screen and (min-width: breakpoints.$breakpoint-sm) {
				bottom: 24px;
			}

			@media only screen and (min-width: breakpoints.$breakpoint-md) {
				bottom: 48px;
			}
		}
	}
	.event-template {
		> div {
			position: relative;
			margin-bottom: 32px;

			@media screen and (min-width: breakpoints.$breakpoint-md) {
				margin-bottom: 72px;
			}
		}

		.event-template-content {
			width: 100vw;
			margin: 0 calc(-50vw);
			max-width: 1920px;
			min-height: 430px;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;

			@media screen and (min-width: breakpoints.$breakpoint-md) {
				min-height: 580px;
			}

			@media screen and (min-width: breakpoints.$breakpoint-xl) {
				min-height: 810px;
			}

			&.dark {
				border-image: linear-gradient(90deg, rgba(20, 20, 20, 0.7) 0%, rgba(29, 29, 29, 0) 80%) fill 1;
			}
			&.sc-white {
				border-image: linear-gradient(90deg, rgba(255, 255, 255, 0.5) 0%, rgba(29, 29, 29, 0) 80%) fill 1;
			}
			&.sc-navy {
				border-image: linear-gradient(90deg, rgba(0, 17, 85, 0.5) 0%, rgba(29, 29, 29, 0) 80%) fill 1;
			}
		}
		.event-template-stage-content {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
}
body[data-wcm-mode='EDIT'] {
	.article {
		.event-template {
			.event-template-stage-content {
				position: relative;
				top: 0;
				left: 0;
				transform: none;
			}
		}
	}
}
