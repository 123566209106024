@use 'lib/styles/defs/breakpoints';

.mod.modAccordion {
	&.no-outer-gutter {
		.aem-Grid & sdx-accordion.hydrated sdx-accordion-item sdx-accordion-item-body,
		sdx-accordion.hydrated sdx-accordion-item sdx-accordion-item-body {
			> div[role='region'] > .component-container {
				// remove paddings added by container - simple layout
				@media screen and (min-width: 0) {
					> div.cmp-container {
						margin: 0;
						padding: 0;

						> div > .aem-Grid > .padding-left-0 {
							padding: 8px;
						}
					}

					> [itemprop='text'] {
						> div.cmp-container {
							margin: 0;
							padding: 0;
						}
					}
				}

				// remove paddings added by container - responsive layout
				> .cmp-container > div > .aem-Grid {
					margin-top: -8px;
					margin-bottom: -8px;
					margin-left: -4px;
					margin-right: -4px;
					width: calc(100% + 8px);

					@media screen and (min-width: breakpoints.$breakpoint-md) {
						margin-left: -8px;
						margin-right: -8px;
						width: calc(100% + 16px);
					}
					@media screen and (min-width: breakpoints.$breakpoint-lg) {
						margin-left: -12px;
						margin-right: -12px;
						width: calc(100% + 24px);
					}
				}
			}
		}
	}
}

.component-container.no_gutter .aem-Grid .mod.modAccordion sdx-accordion.hydrated sdx-accordion-item sdx-accordion-item-body,
.component-container.no_gutter .mod.modAccordion sdx-accordion.hydrated sdx-accordion-item sdx-accordion-item-body {
	// remove paddings added by container - responsive layout
	> div[role='region'] > .component-container > .cmp-container > div > .aem-Grid {
		margin: unset;
		width: 100%;
	}
}
