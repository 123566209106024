.modSkipLinks {
	.skip-link {
		position: absolute;
		width: 0px;
		height: 0px;
		left: -9999px;
		top: -9999px;
	}

	.skip-link:focus,
	.skip-link:active {
		width: auto;
		height: auto;
		left: 5px;
		top: 5px;
		// Competing with header
		z-index: 999999;
		// Make opaque
		padding: 4px;
		background-color: white;
		// Some resets
		border: 0;
		margin: 0;
	}
}
