.modStepper {
	display: flex;
	> .change {
		background: transparent;
		width: 48px;
		height: 48px;
		color: var(--int-color);
		&:hover {
			color: var(--int-color--active);
		}
		border: transparent;
		display: flex;
		flex-shrink: 0;
		align-items: center;
		justify-content: center;
		&:disabled {
			opacity: 0.4;
		}
		&.circled {
			border: 1px solid var(--int-color);
			border-radius: 50%;
		}
	}
	> .steps {
		flex-grow: 1;
		display: flex;
		align-items: flex-end;
		justify-content: center;
		gap: 3%;
		margin: 3%;
	}
	&.editMode > .steps {
		flex-direction: column;
		justify-content: start;
		align-items: center;
	}
	align-items: center;
}
