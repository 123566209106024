@use 'lib/styles/defs/breakpoints';
@import './styles/slider.scss';
@import './styles/arrow.scss';
@import './styles/animations.scss';
@import './styles/hero.scss';

$animation_duration: var(--scs-portal-slider-animation-time, 720ms);

@mixin animation-base {
	animation-duration: $animation_duration;
	animation-timing-function: ease-in-out;
	animation-fill-mode: forwards;
}

scs-portal-slider {
	.content-wrapper {
		position: relative;

		.slides-wrapper {
			position: relative;
			overflow-x: auto;
			scrollbar-width: none;
			width: 100%;
			.slides {
				position: relative;
				width: 100%;
				display: block;
				gap: 20px;
			}
		}
	}
	@media (min-width: breakpoints.$breakpoint-xs) and (max-width: breakpoints.$breakpoint-max-xs) {
		.content-wrapper {
			padding-left: 18px;
			padding-right: 18px;

			.slides-wrapper {
				overflow-x: visible;
			}
		}
	}

	&.animation-speedup {
		--scs-portal-slider-animation-time: 450ms;
	}
}

.scs-portal-slider-item {
	flex-shrink: 0;
	position: relative;
	display: none;

	&[current] {
		display: inline-block;
	}

	&.full,
	&.preview {
		width: 100%;
	}

	&.preview::before {
		content: ' ';
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 2;
	}

	@media (max-width: breakpoints.$breakpoint-max-xs) {
		display: inline-block;
		margin-left: 4px;
		margin-right: 4px;

		&.preview:not([next]):not([previous]) {
			display: none;
		}

		&[first][current] {
			left: 0;
		}

		&[last][current] {
			left: calc(12% - 4px);
		}

		&[previous] {
			&.preview-middle {
				left: calc(-82% - 8px);
			}
			&.preview-left {
				left: calc(-76% - 12px);
			}
		}

		&[next] {
			&.preview-middle {
				left: calc(94% + 4px);
			}
		}

		&.full {
			width: 88%;
			&:not([first]):not([last]) {
				margin-left: 0;
				left: 6%;
			}
		}

		&[first] {
			margin-left: 0;
		}

		&[last] {
			margin-right: 0;
		}

		&.preview {
			width: 88%;
			position: absolute;
		}
	}

	@media (min-width: breakpoints.$breakpoint-sm) and (max-width: breakpoints.$breakpoint-max-md) {
		display: inline-block;
		margin-left: 10px;
		margin-right: 10px;

		&.preview:not([next]):not([previous]) {
			display: none;
		}

		&[first][current] {
			left: 0;
		}

		&[last][current] {
			left: calc(12% - 10px);
		}

		&[previous] {
			&.preview-middle {
				left: calc(-24% - 20px);
			}
			&.preview-left {
				left: calc(-18% - 30px);
			}
		}

		&[next] {
			&.preview-middle {
				left: calc(94% + 10px);
			}
		}

		&.full {
			width: 88%;
			&:not([first]):not([last]) {
				margin-left: 0;
				left: 6%;
			}
		}

		&[first] {
			margin-left: 0;
		}

		&[last] {
			margin-right: 0;
		}

		&.preview {
			width: 30%;
			position: absolute;
		}
	}
	@media (min-width: breakpoints.$breakpoint-lg) {
		display: inline-block;

		&[first][current] {
			left: 0;
		}

		&.lg-first-preview-right-full {
			left: calc(60% + 20px);
		}

		&.lg-first-preview-right {
			left: calc(90% + 40px);
		}

		&.lg-full {
			left: calc(10% - 20px);
		}
		&.lg-left-preview {
			left: calc(-20% - 40px);
		}
		&.lg-right-preview {
			left: 70%;
		}

		&.lg-last-full {
			left: 40%;
		}
		&.lg-last-left-preview {
			left: calc(10% - 20px);
		}

		&.full {
			width: 60%;
		}

		&.preview {
			width: 30%;
			position: absolute;
		}
	}
	&.xs-first-to-previous {
		@include animation-base();
		animation-name: xs-first-to-previous;
	}

	&.xs-next-to-current {
		@include animation-base();
		animation-name: xs-next-to-current;
	}

	&.xs-to-small-left-preview {
		@include animation-base();
		animation-name: xs-to-small-left-preview;
	}

	&.xs-last-to-current {
		@include animation-base();
		animation-name: xs-last-to-current;
	}

	&.xs-current-large-previous {
		@include animation-base();
		animation-name: xs-current-large-previous;
	}

	&.xs-previous-out {
		@include animation-base();
		animation-name: xs-previous-out;
	}

	&.xs-next-in {
		@include animation-base();
		animation-name: xs-next-in;
	}

	&.xs-current-to-previous {
		@include animation-base();
		animation-name: xs-current-to-previous;
	}

	&.xs-previous-to-first {
		@include animation-base();
		animation-name: xs-previous-to-first;
	}

	&.xs-current-to-next-large {
		@include animation-base();
		animation-name: xs-current-to-next-large;
	}

	&.xs-next-out {
		@include animation-base();
		animation-name: xs-next-out;
	}

	&.xs-previous-large-to-current {
		@include animation-base();
		animation-name: xs-previous-large-to-current;
	}

	&.xs-last-to-next {
		@include animation-base();
		animation-name: xs-last-to-next;
	}

	&.xs-previous-in {
		@include animation-base();
		animation-name: xs-previous-in;
	}

	&.xs-previous-to-current {
		@include animation-base();
		animation-name: xs-previous-to-current;
	}

	&.xs-current-to-next {
		@include animation-base();
		animation-name: xs-current-to-next;
	}

	&.md-first-to-previous {
		@include animation-base();
		animation-name: md-first-to-previous;
	}

	&.md-next-large-to-current {
		@include animation-base();
		animation-name: md-next-large-to-current;
	}

	&.md-to-small-left-preview {
		@include animation-base();
		animation-name: md-to-small-left-preview;
	}

	&.md-last-to-current {
		@include animation-base();
		animation-name: md-last-to-current;
	}

	&.md-current-large-previous {
		@include animation-base();
		animation-name: md-current-large-previous;
	}

	&.md-previous-out {
		@include animation-base();
		animation-name: md-previous-out;
	}

	&.md-next-to-current {
		@include animation-base();
		animation-name: md-next-to-current;
	}

	&.md-next-in {
		@include animation-base();
		animation-name: md-next-in;
	}

	&.md-current-to-previous {
		@include animation-base();
		animation-name: md-current-to-previous;
	}

	&.md-previous-to-first {
		@include animation-base();
		animation-name: md-previous-to-first;
	}

	&.md-current-to-next-large {
		@include animation-base();
		animation-name: md-current-to-next-large;
	}

	&.md-previous-large-to-current {
		@include animation-base();
		animation-name: md-previous-large-to-current;
	}

	&.md-last-to-next {
		@include animation-base();
		animation-name: md-last-to-next;
	}

	&.md-previous-in {
		@include animation-base();
		animation-name: md-previous-in;
	}

	&.md-previous-to-current {
		@include animation-base();
		animation-name: md-previous-to-current;
	}

	&.md-current-to-next {
		@include animation-base();
		animation-name: md-current-to-next;
	}

	&.lg-first-to-previous {
		@include animation-base();
		animation-name: lg-first-to-previous;
	}

	&.lg-first-right-to-full {
		@include animation-base();
		animation-name: lg-first-right-to-full;
	}

	&.lg-first-right-preview-to-full-preview {
		@include animation-base();
		animation-name: lg-first-right-preview-to-full-preview;
	}

	&.lg-last-to-full {
		@include animation-base();
		animation-name: lg-last-to-full;
	}

	&.lg-full-to-large-previous {
		@include animation-base();
		animation-name: lg-full-to-large-previous;
	}

	&.lg-preview-right-to-full {
		@include animation-base();
		animation-name: lg-preview-right-to-full;
	}

	&.lg-next-in {
		@include animation-base();
		animation-name: lg-next-in;
	}

	&.lg-full-to-previous {
		@include animation-base();
		animation-name: lg-full-to-previous;
	}

	&.lg-previous-to-first {
		@include animation-base();
		animation-name: lg-previous-to-first;
	}

	&.lg-full-to-first-next {
		@include animation-base();
		animation-name: lg-full-to-first-next;
	}

	&.lg-full-preview-to-preview-next {
		@include animation-base();
		animation-name: lg-full-preview-to-preview-next;
	}

	&.lg-left-full-to-full {
		@include animation-base();
		animation-name: lg-left-full-to-full;
	}

	&.lg-last-to-preview-right {
		@include animation-base();
		animation-name: lg-last-to-preview-right;
	}

	&.lg-previous-to-full {
		@include animation-base();
		animation-name: lg-previous-to-full;
	}

	&.lg-full-to-right-preview {
		@include animation-base();
		animation-name: lg-full-to-right-preview;
	}

	&.lg-right-preview-out {
		@include animation-base();
		animation-name: lg-right-preview-out;
	}
}
