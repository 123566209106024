@keyframes xs-first-to-previous {
	from {
		left: 0;
	}
	to {
		left: calc(-82% - 8px);
	}
}

@keyframes xs-next-to-current {
	from {
		left: calc(94% - 8px);
	}
	to {
		left: 6%;
	}
}

@keyframes xs-to-small-left-preview {
	from {
		left: calc(182% + 8px);
	}
	to {
		left: calc(94% + 4px);
	}
}

@keyframes xs-last-to-current {
	from {
		left: calc(94% + 4px);
	}
	to {
		left: calc(12% - 10px);
	}
}

@keyframes xs-current-large-previous {
	from {
		left: 6%;
	}
	to {
		left: calc(-82% - 8px);
	}
}

@keyframes xs-previous-out {
	from {
		display: inline-block;
		left: calc(-82% - 8px);
	}
	to {
		display: none;
		left: calc(-170% - 8px);
	}
}

@keyframes xs-next-in {
	from {
		left: calc(188% + 4px);
	}
	to {
		left: calc(94% + 4px);
	}
}

@keyframes xs-current-to-previous {
	from {
		left: 6%;
	}
	to {
		left: calc(-76% - 12px);
	}
}

@keyframes xs-previous-to-first {
	from {
		left: calc(-82% - 8px);
	}
	to {
		left: 0;
	}
}
@keyframes xs-current-to-next-large {
	from {
		left: calc(6% - 4px);
	}
	to {
		left: calc(88% + 4px);
	}
}

@keyframes xs-next-out {
	from {
		display: inline-block;
		top: 0;
		left: calc(94% + 8px);
	}
	to {
		left: calc(152% + 8px);
		display: none;
	}
}

@keyframes xs-previous-large-to-current {
	from {
		left: calc(-76% - 12px);
	}
	to {
		left: 6%;
	}
}

@keyframes xs-last-to-next {
	from {
		left: calc(12% - 4px);
	}
	to {
		left: calc(94% + 4px);
	}
}

@keyframes xs-previous-in {
	from {
		display: inline-block;
		left: calc(-170% - 8px);
	}
	to {
		display: inline-block;
		left: calc(-82% - 8px);
	}
}

@keyframes xs-previous-to-current {
	from {
		left: calc(-82% - 8px);
	}
	to {
		left: 6%;
	}
}

@keyframes xs-current-to-next {
	from {
		left: 6%;
	}
	to {
		left: calc(94% + 4px);
	}
}
