@use '~@swisscom/sdx/stylesheets/sdx/variables/colors';

.download-list {
	.downloadlist-item {
		border: 1px solid colors.$color-gray-tint-8;

		.remove-side-borders & {
			border-left: 0;
			border-right: 0;
		}

		&:not(:last-child) {
			border-bottom: 0;
		}

		a {
			text-decoration: none;
			border-bottom: 0;
			display: flex;
			color: var(--int-color);
			div.icon-type-section,
			div.center-image {
				margin-right: 14px;
				align-self: center;
			}
			.text {
				padding-right: 50px;
				word-wrap: break-word;
				align-self: center;
			}
			.icon-indicator-section {
				position: absolute;
				right: 16px;
				align-self: center;
				color: var(--int-color);
			}
			div.center-image {
				display: flex;

				.cmp-image__image {
					width: 30px;
				}
			}
			.normal-link-style &.link {
				&:hover {
					text-decoration: underline;
					text-decoration-thickness: 2px;
				}
			}
		}

		.remove-bottom-border & {
			&:last-child {
				border-bottom: 0;
			}
		}
		.separated-list-items & {
			&:not(:last-child) {
				margin-bottom: 8px;
				border-bottom: 1px solid colors.$color-gray-tint-7;
			}
			border-radius: 12px;
			box-shadow: 0px 4px 16px 0px rgba(51, 51, 51, 0.08);
			a {
				padding: 16px 24px 16px 24px !important;
			}
		}

		.bg-download-list-white & {
			background-color: colors.$color-white;
			border-color: colors.$color-aluminum;
		}
		.without-borders & {
			border: none;
			a {
				padding: 8px 0 8px 0 !important;
				&:hover {
					text-decoration: underline;
					text-decoration-thickness: 2px;
				}
				.icon-indicator-section {
					display: none;
				}
				p {
					font-weight: 400;
				}
			}
		}
	}
}

.text-h2,
.text-h3,
.text-h4,
.text-h5,
.text-h6 {
	.download-list .downloadlist-item a p.text {
		line-height: inherit;
		letter-spacing: inherit;
		font-size: inherit;
		font-weight: inherit;
	}
}

.font--bold {
	.download-list .downloadlist-item a p.text {
		font-weight: 700;
	}
}

.font--semi-bold {
	.download-list .downloadlist-item a p.text {
		font-weight: inherit;
	}
}
