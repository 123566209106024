@use 'lib/styles/defs/breakpoints';

.scs-portal-slider-item {
	.hero-banner {
		.hero-banner-content {
			.banner {
				.banner-content {
					width: 100%;
					sdx-button,
					.modDmcButton,
					.hero-countdown-container {
						opacity: 1;
					}
				}
			}
		}
	}
	&.preview {
		.hero-banner {
			.hero-banner-content {
				.banner {
					.banner-content {
						width: 100%;
						sdx-button,
						.modDmcButton,
						.hero-countdown-container {
							opacity: 0;
						}
					}
				}
			}
		}
	}

	@media (min-width: breakpoints.$breakpoint-xs) and (max-width: breakpoints.$breakpoint-max-xs) {
		.hero-banner {
			.hero-banner-content {
				> .banner {
					justify-content: flex-start;
					.banner-content {
						.text-d3 {
							font-size: 32px;
							line-height: 40px;
						}
					}
				}
			}
		}
	}

	@media (min-width: breakpoints.$breakpoint-md) {
		.hero-banner {
			.hero-banner-content {
				display: block;
				> .banner {
					position: relative;
					width: 50%;
					height: 100%;

					.banner-content {
						z-index: 1;
						position: absolute;
						top: 50%;
						transform: translate(0, -50%);

						.title {
							font-size: 54px;
							line-height: 64px;
						}
					}
				}
			}
			.image-wrapper {
				position: absolute;
				width: 50%;
				left: 50%;
				height: 100%;
				bottom: 0;

				.sdx-stickers {
					opacity: 1;
					right: 5%;
					bottom: 10%;
				}
			}
		}

		&.preview {
			.hero-banner {
				.hero-banner-content {
					.banner {
						width: 100%;

						.banner-content {
							top: 0;
							transform: translate(0, 0);

							.title,
							.text-d3 {
								font-size: 40px;
								line-height: 48px;
							}
						}
					}

					.image-wrapper {
						width: 100%;
						left: 0;
						bottom: 0;
						height: calc(100% - 240px);
						img {
							&:hover {
								transform: none;
							}
						}
						.sdx-stickers {
							opacity: 0;
						}
					}
				}
			}
		}
	}

	&.to-full,
	&.to-preview {
		.hero-banner {
			.hero-banner-content {
				.banner {
					transition: all 720ms;
					.banner-content {
						transition: all 720ms;
					}
					.title {
						transition: all 720ms;
					}
					sdx-button,
					.modDmcButton,
					.hero-countdown-container {
						transition: all 720ms;
					}
				}
				.image-wrapper,
				.sdx-stickers {
					transition: all 720ms;
				}
			}
		}
	}
}
