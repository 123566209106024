.chip {
	.badge-align-left {
		&.badge {
			&.badge--message {
				-webkit-justify-content: flex-start;
				-ms-flex-pack: start;
				justify-content: flex-start;
			}
		}
	}

	.badge-align-right {
		&.badge {
			&.badge--message {
				-webkit-justify-content: flex-end;
				-ms-flex-pack: end;
				justify-content: flex-end;
			}
		}
	}

	& .skinBadgeLink {
		z-index: 999;

		.badge {
			&.badge--message {
				justify-content: flex-start;

				a {
					max-width: none;
					border-bottom: none;

					&.badge__content {
						padding-left: 5px;
						padding-right: 5px;
						cursor: pointer;

						.badge__text {
							letter-spacing: normal;
						}

						&:hover,
						&:focus {
							border: none;
						}
					}
				}
			}
		}
	}

	.bg-gradient-1.badge__content {
		background: radial-gradient(110% 240% at 0% 130%, rgba(19, 163, 244, 0.9) 0%, rgba(22, 158, 255, 0.5) 55%, rgba(16, 136, 222, 0) 130%), #001155;
	}

	.bg-gradient-2.badge__content {
		background: radial-gradient(110% 220% at 0% 140%, #13a3f4 0%, rgba(22, 158, 255, 0.5) 40%, rgba(16, 136, 222, 0) 100%),
			radial-gradient(290% 800% at 220% -440%, #dd1122 30%, #001155 100%);
	}

	.bg-gradient-3.badge__content {
		background: linear-gradient(
			to right bottom,
			#5d1082,
			#4d137b,
			#3d1473,
			#2d146a,
			#1d1361,
			#191a66,
			#14206b,
			#0d266f,
			#163683,
			#1f4797,
			#2758ab,
			#2e6ac0
		);
	}

	.bg-cloud.badge__content {
		background: #f9f9f9;
	}

	.animated-bg-gradient-red.badge__content {
		@extend %twinkleAnimation;
	}
}
