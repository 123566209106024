@use 'node_modules/@swisscom/sdx/stylesheets/sdx/variables/colors';
@use 'lib/styles/defs/breakpoints';

.modCascadingTabs {
	&.mod .inner-filter {
		display: none;

		&.active {
			display: block;
		}
	}

	&.mod .cmp-tabs__tabpanel {
		display: none;

		&.active {
			display: flex;
		}

		& > .component-container {
			width: calc(100% + 24px);
			margin: 0 -4px;

			@media only screen and (min-width: breakpoints.$breakpoint-lg) {
				margin: 0 -12px;
			}
		}
	}

	&.mod {
		.middle-title-text,
		.bottom-title-text {
			color: colors.$color-gray-mid;
			margin-top: 70px;

			&.selected {
				color: colors.$color-sc-navy;
			}
		}

		.step-back {
			width: 100%;
		}
	}

	.cmp-tabs__tab {
		border: none;

		padding-bottom: 12px;
		padding-top: 12px;

		@media only screen and (min-width: breakpoints.$breakpoint-sm) {
			padding: 4px;
		}
		@media only screen and (min-width: breakpoints.$breakpoint-md) {
			padding: 12px;
		}
		@media only screen and (min-width: breakpoints.$breakpoint-lg) {
			padding-bottom: 12px;
			padding-top: 12px;
		}
		&.active,
		&.cmp-tabs__tab--active {
			.tab-link {
				border: solid 1px colors.$color-int-blue--active;
				color: colors.$color-int-blue--active;
			}
		}

		.tab-link {
			display: flex;
			align-items: center;
			height: 72px;
			border-radius: 5px;
			border: 1px solid colors.$color-gray-tint-8;
			color: colors.$color-sc-navy;
			width: 100%;
			hyphens: auto;

			&.active {
				border: solid 1px colors.$color-int-blue--active;
				color: colors.$color-int-blue--active;
				margin-top: 0;
				margin-bottom: 0;
			}

			&:hover,
			&:focus,
			&.active:hover {
				border: solid 1px colors.$color-int-blue;
				color: colors.$color-int-blue--active;
				margin-top: 0;
				margin-bottom: 0;
			}
		}
	}

	.deselect-button {
		cursor: pointer;
		text-decoration: none;
	}

	@media (max-width: breakpoints.$breakpoint-max-xs) {
		ol.hasSelection {
			li:not(.active),
			li button:not(.active) {
				display: none;
			}
		}
		.step-back {
			display: none;
		}
		ol.hasSelection + .step-back {
			display: block;
		}
	}

	.hide-non-selected-content & {
		&.mod {
			.middle-title-text,
			.bottom-title-text,
			.description-middle,
			.description-bottom {
				display: none;
				&.selected {
					display: block;
				}
			}
		}
	}
}
