.modLottieAnimatedSVG {
	.animation-holder {
		display: inline-block;
	}
	.svg-text {
		letter-spacing: -0.1px;
		text-align: center;
		font-weight: 600;
		font-size: 20px;
		line-height: 24px;

		@media only screen and (max-width: 1024px) {
			margin-left: 20px;
		}
		@media only screen and (min-width: 1025px) {
			flex-direction: column;
			margin-top: 10px;
		}
	}
}
.animation-with-text {
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	@media only screen and (min-width: 1025px) {
		flex-direction: column;
	}
	@media only screen and (max-width: 768px) {
		justify-content: left;
	}
}
