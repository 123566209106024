@use 'lib/styles/defs/breakpoints';

.logo {
	display: flex;
	width: 100%;
	img {
		height: 32px !important;
		width: auto !important;

		@media screen and (max-width: breakpoints.$breakpoint-md) {
			height: 24px !important;
		}
	}
	&.logo-align-left {
		justify-content: flex-start;
	}
	&.logo-align-right {
		justify-content: flex-end;
	}
	&.logo-align-center {
		justify-content: center;
	}
}
