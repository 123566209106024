.modBrightcoveVideo {
	.video-player-wrapper {
		width: 100%;
		transition: padding-bottom 0.3s ease-in-out;
		height: auto;
		position: relative;
		aspect-ratio: 16/9;

		> * {
			width: 100%;
			height: auto;
			max-height: 100vh;
			left: -10000px;
		}

		.video-js .vjs-tech {
			position: static;
			height: auto;
		}

		.vjs-default-skin {
			.vjs-control-bar {
				background-color: rgba(255, 255, 255, 0.65);
				color: #444;
				height: 45px;
				border-top: none;

				button > * {
					position: static;

					&.vjs-control-text {
						position: absolute;
					}
				}
			}

			.vjs-big-play-button,
			.vjs-time-divider {
				display: none;
			}

			.vjs-duration {
				-webkit-order: 6;
				-webkit-box-ordinal-group: 6;
				-moz-box-ordinal-group: 6;
				-ms-flex-order: 6;
				order: 6;
				display: none;
			}

			&.vjs-has-started .vjs-duration {
				display: block;
			}

			.vjs-play-progress {
				background: none;
				background-color: #2d90ec;
				margin-top: 0;
				min-width: 1px;
			}

			.vjs-volume-menu-button:hover {
				.vjs-volume-level {
					background-color: #2d90ec;
				}
			}

			.vjs-mouse .vjs-menu {
				margin: 0 -14px 47px -14px;
			}

			.vjs-progress-holder:before {
				height: 7px;
				margin-top: 0;
				/*position: relative; */
				background-color: #8b8e8c;
				border-radius: 4px;
			}

			.vjs-mouse .vjs-progress-holder {
				padding: 12px 0 14px;
			}

			.vjs-mouse .vjs-control {
				height: 45px;
				padding-top: 6px;
			}

			.vjs-time-controls > div {
				display: inline;
				vertical-align: sub;
			}

			.vjs-mouse .vjs-control.vjs-current-time,
			.vjs-mouse .vjs-control.vjs-duration {
				padding-top: 8px;
			}

			.vjs-play-progress:before,
			.vjs-volume-level:before {
				color: #2d90ec;
			}

			.vjs-play-control:hover,
			.vjs-mute-control:hover,
			.vjs-fullscreen-control:hover {
				color: #2d90ec;
			}
		}
	}

	// Android deserves special treatment for the controls…
	&.is-android {
		video::-webkit-media-controls {
			display: none !important;
		}

		&.scs-setup-and-ready {
			.video-playbutton-container {
				display: none;
			}

			.video-container {
				position: relative;
				width: 100%;
			}

			&.is-idle {
				.video-playbutton-container {
					position: absolute;
					display: block;
					width: 100%;
					height: 100%;
					z-index: 1;

					.video-playbutton-wrapper {
						display: table;
						width: 100%;
						height: 100%;

						.video-playbutton-wrapper-inner {
							display: table-cell;
							text-align: center;
							vertical-align: middle;
							height: 100%;

							.video-playbutton {
								display: inline-block;
								width: 80px;
								height: 80px;
								background: url('/etc/designs/base/resources/icons/sc_playbutton_80.png') no-repeat;
							}
						}
					}
				}

				.vjs-control-bar {
					display: none;
				}
			}
		}

		.vjs-share-control {
			display: none;
		}
	}

	.vjs-ima3-ad-container {
		z-index: 1;
	}
}
