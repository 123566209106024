@use 'lib/styles/defs/breakpoints';

.modInputs {
	sdx-input-group.distributed-width {
		& > sdx-input-item {
			flex: 1;
		}
	}

	sdx-input-item {
		img {
			padding-right: 37px;
		}
		.grid-layout-image {
			vertical-align: text-top;
		}
	}
}

.inputs-align-left,
.inputs-align-right,
.inputs-align-center {
	.modInputs {
		display: flex;
	}
}

.inputs-align-left {
	.modInputs {
		justify-content: flex-start;
	}
}

.inputs-align-right {
	.modInputs {
		justify-content: flex-end;
	}
}

.inputs-align-center {
	.modInputs {
		justify-content: center;
	}
}
