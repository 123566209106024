@use 'lib/styles/defs/breakpoints';

@mixin reset-horizontal-paddings($side) {
	padding-#{$side}: 4px !important;
	@media screen and (min-width: breakpoints.$breakpoint-md) {
		padding-#{$side}: 8px !important;
	}
	@media screen and (min-width: breakpoints.$breakpoint-lg) {
		padding-#{$side}: 12px !important;
	}
}

@mixin gradientPink {
	background: linear-gradient(214.35deg, #d8e1f9 -0.2%, #5990f9 55.31%, #942fbe 98.75%);
}
