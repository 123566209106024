@use '~@swisscom/sdx/stylesheets/sdx/variables/colors';

.modSkeleton {
	position: relative;

	&.animate {
		&::before {
			content: '';
			z-index: 100;
			position: absolute;
			width: 100%;
			height: 100%;
			background-color: colors.$color-gray-tint-8;
			border-radius: 6px;
			opacity: 1;
		}

		&::after {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			transform: translateX(-100%);
			background-image: linear-gradient(
				90deg,
				rgba(colors.$color-white, 0) 0,
				rgba(colors.$color-white, 0.2) 20%,
				rgba(colors.$color-white, 0.5) 60%,
				rgba(colors.$color-white, 0)
			);
			animation: shimmer 2s infinite;
			content: '';
			z-index: 101;
		}
	}

	&.fade-out {
		&::before {
			animation-name: fadeOut;
			animation-duration: 2s;
			animation-timing-function: ease-in-out;
			animation-fill-mode: forwards;
		}
		&::after {
			animation:
				shimmer 2s infinite,
				fadeOut 2s ease-in-out forwards;
		}
	}

	@keyframes shimmer {
		100% {
			transform: translateX(100%);
		}
	}

	@keyframes fadeOut {
		100% {
			opacity: 0;
		}
	}
}
