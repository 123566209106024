/* These styles are only temporary for demo */
/* In the future we should render sdx html */
@use 'node_modules/@swisscom/sdx/stylesheets/sdx/variables/colors';
@use 'lib/styles/defs/breakpoints';

.yourselection-wrapper {
	width: 100%;
	border: 1px solid colors.$color-gray-tint-8;
}

.cmp-yourselection-title {
	border-bottom: solid 1px colors.$color-gray-tint-8;
	padding: 16px;

	p {
		font-size: 24px;
		line-height: 40px;
		letter-spacing: -0.75px;
	}
}

.cmp-yourselection-body {
	position: relative;
	padding: 16px;
}

.cmp-yourselection-price {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	margin-top: 20px;
	margin-bottom: 40px;
	@media screen and (min-width: breakpoints.$breakpoint-md + 1px) {
		flex-direction: row;
	}
}

.cmp-yourselection-price-text,
.cmp-yourselection-price-discount {
	flex: 1;
}

.cmp-yourselection-price-discount {
	@media screen and (min-width: breakpoints.$breakpoint-md + 1px) {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
	}
}

.cmp-yourselection-buttons {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	flex-direction: column-reverse;
	@media screen and (min-width: breakpoints.$breakpoint-md + 1px) {
		flex-direction: row;
	}

	.button-container {
		&.button-container-align-right {
			width: 100%;
		}

		@media screen and (max-width: breakpoints.$breakpoint-md) {
			margin-bottom: 18px;
		}

		.component-container.aem-GridColumn {
			padding: 0 !important;
		}

		.component-button.aem-GridColumn {
			padding: 0 !important;
		}
	}

	.link-container {
		display: flex;
		align-items: center;
	}
}
