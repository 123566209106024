$thumb-width: var(--scs-portal-range-slider-thumb-width, 48px);
$thumb-height: 4px;

$track-width: 40%;
$track-height: $thumb-height;
$track-radius: 5px;

@mixin thumb {
	background: var(--sdx-color-int-blue);
	border: none;
	border-radius: $track-radius;
	width: $thumb-width;
	height: $thumb-height;
	cursor: pointer;

	&:active {
		outline: 0;
	}

	&:hover {
		background: var(--sdx-color-int-blue--active);
	}
}

@mixin track {
	background: var(--sdx-color-gray-tint-8);
	border: none;
	border-radius: $track-radius;
	width: 100%;
	height: $track-height;
}

.scs-portal-range-slider {
	-webkit-appearance: none;
	border-radius: $track-radius;
	overflow: hidden;
	width: $track-width;
	height: $track-height;
	margin: 0 0.5 * (100% - $track-width);
	padding: var(--sdx-baseline-3) 0 var(--sdx-baseline) 0;
	cursor: pointer;
	display: block;

	&:focus {
		outline: 0;
	}

	&::-moz-focus-outer {
		border: 0;
	}

	&[max='0'],
	&[max^='-'] {
		visibility: hidden;
	}

	// The indicator
	&::-webkit-slider-thumb {
		-webkit-appearance: none;
		@include thumb;
	}
	&.first-position::-webkit-slider-thumb {
		border-top-left-radius: $track-radius;
		border-bottom-left-radius: $track-radius;
	}
	&.last-position::-webkit-slider-thumb {
		border-top-right-radius: $track-radius;
		border-bottom-right-radius: $track-radius;
	}

	&::-moz-range-thumb {
		@include thumb;
	}
	&.first-position::-moz-range-thumb {
		border-top-left-radius: $track-radius;
		border-bottom-left-radius: $track-radius;
	}
	&.last-position::-moz-range-thumb {
		border-top-right-radius: $track-radius;
		border-bottom-right-radius: $track-radius;
	}

	// The groove in which the indicator slides
	&::-webkit-slider-runnable-track {
		-webkit-appearance: none;
		@include track;
	}

	&::-moz-range-track {
		@include track;
	}
}
