@use 'lib/styles/defs/breakpoints';

scs-portal-content-filter {
	display: block;
	> sdx-scroll-view {
		.filter-handle-wrapper {
			display: flex;
			gap: 16px;
			padding: 8px 0 32px 8px;

			> .filter-handle {
				position: relative;
				width: 160px;
				height: 104px;
				display: flex;
				flex-direction: column;
				border-radius: 12px;
				border: 1px solid var(--sdx-color-gray-tint-7);
				background-color: var(--sdx-color-sc-white);
				transition: all 400ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
				text-align: center;
				padding: 8px 8px 0 8px;
				flex-shrink: 0;

				&:first-child {
					&::after {
						content: '';
						position: absolute;
						height: 24px;
						width: 2px;
						right: -10px;
						top: 38px;
						background: color-mix(in srgb, var(--sdx-color-gray-tint-7) 50%, var(--sdx-color-sc-white));
						border-radius: 1px;
					}

					@media screen and (min-width: breakpoints.$breakpoint-md) {
						margin-right: 16px;

						&::after {
							right: -17px;
						}
					}
				}

				.title {
					flex-basis: 40%;
					text-align: center;
				}

				&:hover {
					border: 1px solid var(--sdx-color-gray-tint-7);
					border-color: var(--sdx-color-gray-tint-4);
					box-shadow: color-mix(in srgb, var(--sdx-color-gray) 16%, transparent) 0px 4px 20px 0px;
					cursor: pointer;

					.image-wrapper {
						img {
							top: 0;
						}
					}
				}

				&.active {
					box-shadow: 0 0 0 2px var(--sdx-color-int-blue);
					transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
					> h5.title {
						color: var(--sdx-color-int-blue);
						transition: color cubic-bezier(0.4, 0, 0.2, 1);
					}
				}

				.image-wrapper {
					position: relative;
					flex-basis: 60%;
					overflow: hidden;

					img {
						display: block;
						position: absolute;
						width: 100%;
						height: 72px;
						object-fit: scale-down;
						top: 20px;
						transition: top 400ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
					}
				}
			}

			.centered > & {
				justify-content: center;
				align-items: center;
			}
		}
	}

	.category-wrapper {
		[data-category] .responsivegrid div.aem-Grid:not([data-wcm-mode='EDIT']) {
			display: flex;

			flex-wrap: wrap;
		}
	}
}
body[data-wcm-mode='EDIT'] {
	.category-wrapper {
		[data-category] {
			outline: 1px solid var(--sdx-color-int-green);
			margin: 30px 0;
			padding: 15px 0;
			position: relative;
			border-radius: 6px;

			&::before {
				content: attr(data-category);
				position: absolute;
				top: -15px;
				left: 5px;
				background-color: var(--sdx-color-sc-white);
				padding: 2px 5px;
				border: 1px solid var(--sdx-color-int-red);
				border-radius: 6px;
				font-size: 15px;
				color: var(--sdx-color-sc-navy);
			}
		}
	}
}
