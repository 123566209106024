@use '~@swisscom/sdx/stylesheets/sdx/variables/colors';

.modScrollingArrow {
	animation: arrow-animation 3s infinite;
	cursor: pointer;
	bottom: 80px;
	left: 50%;
	z-index: 1000;
	text-align: center;
	margin-left: -25px;
	width: 50px;
	height: 50px;
	border: solid 1px colors.$color-blue;
	background-color: colors.$color-white;
	border-radius: 50%;

	&.mod {
		position: fixed;
	}

	@media print {
		display: none;
	}
}

@keyframes arrow-animation {
	0% {
		transform: translate(0, 0);
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		transform: translate(0, 20px);
		opacity: 0;
	}
}
