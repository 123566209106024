.modIFrame {
	position: relative;

	.iframeLoadingAnimation {
		background: #fff url('/etc/designs/base/resources/rotating-loader.gif');
		background-position: 50% 50%;
		background-repeat: no-repeat;
		min-height: 32px;
		min-width: 32px;
	}

	iframe {
		display: block;
	}

	&.fixedAspectRatio {
		height: 0;
		// Default to 100/(16/9)
		padding-bottom: 56.25%;
		position: relative;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}
