sdx-content-slider > div {
	display: grid;
	.xf-content-height > .aem-Grid {
		&::after,
		&::before {
			content: none;
		}
	}
}

.modContentSlider {
	.cmp-teaser {
		height: 100%;
	}
}
