@use '~@swisscom/sdx/stylesheets/sdx/variables/sizes' as s;
@use 'lib/styles/defs/breakpoints';

$baseline: s.$baseline;
$values: 5, 8, 10, 13, 16, 20;
$mobileModifier: 8, 24, 32, 32, 40, 56;
$tabletModifier: 0, 16, 24, 16, 24, 32;
$defaultModifier: 0, 0, 0, 0, 0, 0;

@mixin generate-classes($modifier: $defaultModifier) {
	@for $index from 1 through length($values) {
		$val: nth($values, $index);
		$mod: nth($modifier, $index);
		$size: $baseline * $val - $mod;

		&.margin-top-#{$val} {
			margin-top: $size;
		}
		&.margin-bottom-#{$val} {
			margin-bottom: $size;
		}
		&.padding-top-#{$val} {
			padding-top: $size;
		}
		&.padding-bottom-#{$val} {
			padding-bottom: $size;
		}
	}
}

.divider {
	@include generate-classes($mobileModifier);

	@media screen and (min-width: breakpoints.$breakpoint-md) {
		@include generate-classes($tabletModifier);
	}

	@media screen and (min-width: breakpoints.$breakpoint-lg) {
		@include generate-classes();
	}

	&.divider--transparent {
		border-bottom-color: transparent;
	}

	&.full {
		width: 100vw !important;
		position: relative;
		left: calc(-50vw + 50%);
	}
}
