@use '~@swisscom/sdx/stylesheets/sdx/variables/colors';
@use 'lib/styles/defs/breakpoints';

.modCallMeBack {
	&.as-button {
		sdx-dialog {
			width: 100%;
			sdx-button {
				width: 100%;
			}
		}

		@media (min-width: breakpoints.$breakpoint-sm) {
			sdx-dialog {
				width: auto;
			}
		}
	}
}

.call-me-back-wizard {
	width: 100%;
	@media screen and (min-width: breakpoints.$breakpoint-sm) {
		width: 400px;
		&.only-direct-calls {
			width: 400px;
		}
	}
	@media screen and (min-width: breakpoints.$breakpoint-md) {
		width: 630px;
	}

	&.loader {
		display: flex;
		justify-content: center;
		min-height: 52px;
	}

	.bg--dark {
		background-color: transparent;
	}

	.summary-report {
		p {
			display: flex;
			justify-content: space-between;
		}
	}

	.card-holder {
		min-height: 194px;
		sdx-card {
			display: block;
		}
	}

	&.call-request {
		min-height: 168px;
		@media screen and (min-width: breakpoints.$breakpoint-md) {
			min-height: 76px;
		}
	}
}
