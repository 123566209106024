@use '~@swisscom/sdx/stylesheets/sdx/variables/colors';
@use 'lib/styles/defs/breakpoints';

.carousel {
	overflow: initial;

	&.job-style {
		.cmp-carousel {
			.cmp-carousel__indicators {
				display: none;
			}

			.cmp-carousel__actions {
				position: absolute;
				top: calc(50% - 20px);
				width: 100%;

				.cmp-carousel__action {
					position: absolute;
					height: 40px;
					width: 40px;
					color: #086adb;
					background-color: #e4e9ec;
					padding-top: 4px;
					border-radius: 4px;
					opacity: 0.8;

					&:hover {
						opacity: 1;
					}
				}

				.cmp-carousel__action--previous {
					padding-left: 7px;
					left: 0;
				}

				.cmp-carousel__action--next {
					padding-left: 10px;
					right: 0;
				}
			}
		}
	}

	&.sdx-style {
		.cmp-carousel__indicators {
			width: 100%;
			.cmp-carousel__indicator {
				background-color: transparent;
			}

			.cmp-carousel__indicator--active {
				opacity: 1;

				&:before {
					transform: scale(1);
				}
			}
		}

		&.full-bleed {
			overflow: visible;

			.cmp-carousel__item--active,
			.cmp-container {
				min-height: 110px;
			}

			.cmp-carousel__actions {
				@media only screen and (min-width: breakpoints.$breakpoint-lg) {
					.carousel__button-prev {
						left: -50px;
					}
					.carousel__button-next {
						right: -50px;
					}
				}

				@media only screen and (min-width: breakpoints.$breakpoint-ul) {
					.carousel__button-prev {
						left: -75px;
					}
					.carousel__button-next {
						right: -75px;
					}
				}
			}
		}
	}

	&.circle-style {
		.carousel__button-next,
		.carousel__button-prev {
			background-color: colors.$color-white;
			border-radius: 50%;
			border: 3px solid colors.$color-blue;
			opacity: 0.8;
			padding: 5px;
		}

		.carousel__button-wrapper {
			top: calc(50% - 60px);
			bottom: inherit;
			height: 60px;

			i {
				font-size: 30px;
			}
		}

		.carousel__button-next {
			@media only screen and (max-width: breakpoints.$breakpoint-max-md) {
				margin-right: 5px;
			}
			@media only screen and (max-width: breakpoints.$breakpoint-max-sm) {
				margin-right: 16px;
			}

			i {
				padding: 7px 5px 7px 9px;
			}
		}

		.carousel__button-prev {
			@media only screen and (max-width: breakpoints.$breakpoint-max-md) {
				margin-left: 5px;
			}
			@media only screen and (max-width: breakpoints.$breakpoint-max-sm) {
				margin-left: 16px;
			}

			i {
				padding: 7px 9px 7px 5px;
			}
		}
	}

	.carousel__slider {
		cursor: auto;
	}

	&.navigation-white {
		.cmp-carousel__indicator::before {
			background: colors.$color-white;
		}

		.icon.icon-chevron-left,
		.icon.icon-chevron-right {
			color: colors.$color-white;
		}
	}

	&.navigation-white-blue {
		@media only screen and (min-width: breakpoints.$breakpoint-md) {
			.cmp-carousel__indicator::before {
				background: colors.$color-white;
			}

			.icon.icon-chevron-left,
			.icon.icon-chevron-right {
				color: colors.$color-white;
			}
		}
	}

	.page.home-page & {
		&:not(.circle-style).sdx-style {
			@media only screen and (max-width: breakpoints.$breakpoint-md) {
				.carousel__button-next {
					right: 0;
					padding-right: 0;
				}
				.carousel__button-prev {
					left: 0;
					padding-left: 0;
				}
			}
		}
	}
}
