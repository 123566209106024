%twinkleAnimation {
	background-image: radial-gradient(transparent 47%, #001155 48%), radial-gradient(ellipse 150% 110% at 20% 10%, #9b1339 4%, transparent 35%),
		radial-gradient(ellipse 130% 120% at 70% 80%, #0b82dc 4%, transparent 35%),
		radial-gradient(ellipse 120% 160% at 10% 50%, #9b1339 2%, transparent 30%),
		radial-gradient(ellipse 100% 110% at 90% 5%, #0b82dc 2%, transparent 30%),
		radial-gradient(ellipse 100% 70% at 50% 55%, #a63297 3%, transparent 40%), radial-gradient(circle at 50% 50%, #001155, #001155 100%);
	background-repeat: repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
	animation-name: twinkle;
	animation-duration: 60s;
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;
}

@keyframes twinkle {
	0% {
		background-position:
			0% 0%,
			0% 0%,
			0% 0%,
			0% 0%,
			0% 0%,
			0% 0%;
		background-size:
			5px 5px,
			400% 400%,
			400% 400%,
			400% 400%,
			400% 400%,
			400% 400%;
	}

	10% {
		background-position:
			0% 0%,
			50% 20%,
			50% 20%,
			50% 20%,
			50% 20%,
			50% 20%;
		background-size:
			5px 5px,
			350% 350%,
			350% 350%,
			350% 350%,
			350% 350%,
			350% 350%;
	}

	20% {
		background-position:
			0% 0%,
			40% 70%,
			40% 70%,
			40% 70%,
			40% 70%,
			40% 70%;
		background-size:
			5px 5px,
			300% 300%,
			300% 300%,
			300% 300%,
			300% 300%,
			300% 300%;
	}

	30% {
		background-position:
			0% 0%,
			90% 90%,
			90% 90%,
			90% 90%,
			90% 90%,
			90% 90%;
		background-size:
			5px 5px,
			250% 250%,
			250% 250%,
			250% 250%,
			250% 250%,
			250% 250%;
	}

	40% {
		background-position:
			0% 0%,
			70% 20%,
			70% 20%,
			70% 20%,
			70% 20%,
			70% 20%;
		background-size:
			5px 5px,
			150% 150%,
			150% 150%,
			150% 150%,
			150% 150%,
			150% 150%;
	}

	50% {
		background-position:
			0% 0%,
			10% 40%,
			10% 40%,
			10% 40%,
			10% 40%,
			10% 40%;
		background-size:
			5px 5px,
			110% 110%,
			110% 110%,
			110% 110%,
			110% 110%,
			110% 110%;
	}

	60% {
		background-position:
			0% 0%,
			90% 60%,
			90% 60%,
			90% 60%,
			90% 60%,
			90% 60%;
		background-size:
			5px 5px,
			200% 200%,
			200% 200%,
			200% 200%,
			200% 200%,
			200% 200%;
	}

	70% {
		background-position:
			0% 0%,
			20% 80%,
			20% 80%,
			20% 80%,
			20% 80%,
			20% 80%;
		background-size:
			5px 5px,
			250% 250%,
			250% 250%,
			250% 250%,
			250% 250%,
			250% 250%;
	}

	80% {
		background-position:
			0% 0%,
			100% 100%,
			100% 100%,
			100% 100%,
			100% 100%,
			100% 100%;
		background-size:
			5px 5px,
			300% 300%,
			300% 300%,
			300% 300%,
			300% 300%,
			300% 300%;
	}

	90% {
		background-position:
			0% 0%,
			50% 0%,
			50% 0%,
			50% 0%,
			50% 0%,
			50% 0%;
		background-size:
			5px 5px,
			350% 350%,
			350% 350%,
			350% 350%,
			350% 350%,
			350% 350%;
	}

	100% {
		background-position:
			0% 0%,
			0% 0%,
			0% 0%,
			0% 0%,
			0% 0%,
			0% 0%;
		background-size:
			5px 5px,
			400% 400%,
			400% 400%,
			400% 400%,
			400% 400%,
			400% 400%;
	}
}

// Teaser clusters

@keyframes ts-cluster-slide-in-from-top-left {
	from {
		transform: translate(-5%, -5%);
	}
	to {
		transform: translate(0);
	}
}
@keyframes ts-cluster-slide-in-from-top-right {
	from {
		transform: translate(5%, -5%);
	}
	to {
		transform: translate(0);
	}
}
@keyframes ts-cluster-slide-in-from-bottom-left {
	from {
		transform: translate(-5%, 5%);
	}
	to {
		transform: translate(0);
	}
}
@keyframes ts-cluster-slide-in-from-bottom-right {
	from {
		transform: translate(5%, 5%);
	}
	to {
		transform: translate(0);
	}
}

@keyframes flipBubble {
	0% {
		transform: rotateY(0deg);
	}
	10% {
		transform: rotateY(180deg);
	}
	50% {
		transform: rotateY(180deg);
	}
	60% {
		transform: rotateY(360deg);
	}
	100% {
		transform: rotateY(360deg);
	}
}
